import request from '../utils/request'

//上传图片
export const uploadImage = (data)=>{ 
    return request({
        url: '/sys/picture/uploadImage',
        headers: {
            "Content-type": "multipart/form-data",
        },
        method: 'post',
        data: data
    });
}
//获取分页
export const page = (query) => {
    return request({
        url: '/sys/helpManual/page',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

export const helpManualAdd = (querys) => {
    return request({
        url: '/sys/helpManual/add',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(querys),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

//获取每行
///api/sys/helpManual/list
export const helpManualItem = (id) => {
    return request({
        url: '/sys/helpManual/list',
        method: 'get',
        params: {id},
        
    });
};

//搜索
export const helpManualSearch = (search) => {
    return request({
        url: '/sys/helpManual/update',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(search),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//上传视频
export const uploadVideo = (data)=>{ 
    return request({
        url: '/sys/picture/uploadVideo',
        headers: {
            "Content-type": "multipart/form-data",
        },
        method: 'post',
        data: data
    });
}

//编辑更新
export const edit = (querys) => {
    return request({
        url: '/sys/helpManual/update',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(querys),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//删除

export const Delete = (helpManualId) => {
    return request({
        url: '/sys/helpManual/delete',
        method: 'POST',
        dataType: "json",
        params:{helpManualId},
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};